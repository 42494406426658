import React, { useState } from "react";
import { createAcademy } from "../firebase/profileFunctions";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

const RegisterAcademy = () => {
  const [academyData, setAcademyData] = useState({
    Academy: "",
    Address: "",
    City: "",
    State: "",
    Country: "",
    Phone: "",
    Professor: "",
  });

  const [errorData, setErrorData] = useState({
    errorAcademy: "",
    errorAddress: "",
    errorCity: "",
    errorState: "",
    errorCountry: "",
    errorPhone: "",
    errorProfessor: "",
  });

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSignUp = async () => {
    let valid = true;

    if (!academyData.Academy) {
      setErrorData((prev) => ({ ...prev, errorAcademy: "errorFormInput" }));
      valid = false;
    } else {
      setErrorData((prev) => ({ ...prev, errorAcademy: "" }));
    }

    if (!academyData.Address) {
      setErrorData((prev) => ({ ...prev, errorAddress: "errorFormInput" }));
      valid = false;
    } else {
      setErrorData((prev) => ({ ...prev, errorAddress: "" }));
    }

    if (!academyData.City) {
      setErrorData((prev) => ({ ...prev, errorCity: "errorFormInput" }));
      valid = false;
    } else {
      setErrorData((prev) => ({ ...prev, errorCity: "" }));
    }

    if (!academyData.State) {
      setErrorData((prev) => ({ ...prev, errorState: "errorFormInput" }));
      valid = false;
    } else {
      setErrorData((prev) => ({ ...prev, errorState: "" }));
    }

    if (!academyData.Country) {
      setErrorData((prev) => ({ ...prev, errorCountry: "errorFormInput" }));
      valid = false;
    } else {
      setErrorData((prev) => ({ ...prev, errorCountry: "" }));
    }

    if (!academyData.Professor) {
      setErrorData((prev) => ({ ...prev, errorProfessor: "errorFormInput" }));
      valid = false;
    } else {
      setErrorData((prev) => ({ ...prev, errorProfessor: "" }));
    }

    if (!academyData.Phone) {
      setErrorData((prev) => ({ ...prev, errorPhone: "errorFormInput" }));
      valid = false;
    } else {
      setErrorData((prev) => ({ ...prev, errorPhone: "" }));
    }

    if (valid) {
      const standardizedData = {
        ...academyData,
        Academy: academyData.Academy.toUpperCase(), // Convert to uppercase
      };

      try {
        const success = await createAcademy(standardizedData); // New function to add the academy
        if (success) {
          setErrorMessage("Academy registered.");
        }
      } catch (e) {
        // Check for specific error codes and set appropriate messages
        if (e.message.includes("insufficient permissions")) {
          setErrorMessage(
            "You must be an approved Black Belt to add an academy."
          );
        } else if (e.message.includes("name already exists")) {
          setErrorMessage("Academy is already registered.");
        } else {
          setErrorMessage("Failed to register academy. Please try again.");
        }
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>
          Pacific Federation of Brazilian Jiu Jitsu | Register Academy
        </title>
      </Helmet>
      <div className="section-padding signup-form">
        <Container>
          <h2>Register Academy</h2>
          <div className="signup">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <input
                  type="text"
                  className={errorData.errorAcademy}
                  placeholder="Academy Name"
                  value={academyData.Academy}
                  onChange={(e) =>
                    setAcademyData({ ...academyData, Academy: e.target.value })
                  }
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <input
                  type="text"
                  className={errorData.errorAddress}
                  placeholder="Address"
                  value={academyData.Address}
                  onChange={(e) =>
                    setAcademyData({ ...academyData, Address: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <input
                  type="text"
                  className={errorData.errorCity}
                  placeholder="City"
                  value={academyData.City}
                  onChange={(e) =>
                    setAcademyData({ ...academyData, City: e.target.value })
                  }
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <input
                  type="text"
                  className={errorData.errorState}
                  placeholder="State"
                  value={academyData.State}
                  onChange={(e) =>
                    setAcademyData({ ...academyData, State: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <input
                  type="text"
                  className={errorData.errorCountry}
                  placeholder="Country"
                  value={academyData.Country}
                  onChange={(e) =>
                    setAcademyData({ ...academyData, Country: e.target.value })
                  }
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <input
                  type="text"
                  className={errorData.errorPhone}
                  placeholder="Phone Number"
                  value={academyData.Phone}
                  onChange={(e) =>
                    setAcademyData({ ...academyData, Phone: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <input
                  type="text"
                  className={errorData.errorProfessor}
                  placeholder="Head Professor"
                  value={academyData.Professor}
                  onChange={(e) =>
                    setAcademyData({
                      ...academyData,
                      Professor: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="row justify-content-center">
              {errorMessage && (
                <p className="text-center" style={{ color: "red" }}>
                  {errorMessage}
                </p>
              )}
              <button
                onClick={handleSignUp}
                className="submit-btn button-auto-width"
              >
                Register Academy
              </button>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default RegisterAcademy;
