import React from "react";
import { Container } from "react-bootstrap";
import Logo from "../../images/logo2.png";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import "./footer.scss";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer-sec text-center">
      <Container>
        <div className="footer-content">
          <Link to="/">
            <img src={Logo} alt="Logo" />
          </Link>
          <div className="social-icons">
            <a href="https://www.facebook.com/pacificfederationbjj/">
              <span>
                <FaFacebook />
              </span>
            </a>
            <a href="https://www.instagram.com/pacificfederationbjj/">
              <span>
                <FaInstagram />
              </span>
            </a>
            <a href="https://www.youtube.com/channel/UCYZf4cg73n2mqz6HQakOiOQ/featured">
              <span>
                <FaYoutube />
              </span>
            </a>
          </div>
          <div className="lang-change">
            <div id="google_translate_element"></div>
          </div>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
