import React from "react";
import { Container } from "react-bootstrap";
import InfoImage1 from "../../images/info-img1.jpg";
import InfoImage2 from "../../images/info-img2.jpg";
import "./info.scss";
import { Link } from "react-router-dom";
import PacificRule from "../../downloads/PACIFIC-FEDERATION-BJJ.pdf";
import BeltRank from "../../downloads/GRADUATION-SYSTEM-1.pdf";
import MIGHTY from "../../images/MIGHTY-MITE-RULES-scaled.png";
import PEE from "../../images/PEE-WEE-RULES-1-scaled.png";
import Junior from "../../images/JUNIOR-RULES-scaled.png";
import Teen from "../../images/J.R.-TEEN-RULES-scaled.png";
import TeanAger from "../../images/TEENAGER-RULES-scaled.png";
import Adult from "../../images/ADULT-RULES-scaled.png";
import { FaFileDownload } from "react-icons/fa";
import MIGHTY_Pdf from "../../downloads/MIGHTY-MITE-RULES.pdf";
import PEE_Pdf from "../../downloads/PEE-WEE-RULES.pdf";
import Junior_Pdf from "../../downloads/JUNIOR-RULES.pdf";
import Teen_Pdf from "../../downloads/J.R.-TEEN-RULES.pdf";
import TeanAger_Pdf from "../../downloads/TEENAGER-RULES.pdf";
import Adult_Pdf from "../../downloads/ADULT-RULES.pdf";
import { Helmet } from "react-helmet";

function Info() {
  return (
    <>
      <Helmet>
        <title>Pacific Federation of Brazilian Jiu Jitsu | Info</title>
      </Helmet>
      <div className="section-padding info-sec">
        <Container>
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-6 col-lg-4 text-center mb-4 mb-md-0">
              <div className="info-box">
                <img className="w-100" src={InfoImage1} alt="InfoImage1" />
                <br />
                <Link to={PacificRule} target="_blank" rel="noreferrer">
                  DOWNLOAD COMPETITION RULES BOOK
                </Link>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 text-center">
              <div className="info-box">
                <img className="w-100" src={InfoImage2} alt="InfoImage1" />
                <br />
                <Link to={BeltRank} target="_blank" rel="noreferrer">
                  DOWNLOAD BELT RANK CHART
                </Link>
              </div>
            </div>
          </div>
          <div className="row text-center mt-5">
            <div className="col-12">
              <h3>RULES BY AGE GROUP</h3>
              <div className="row justify-content-center">
                <div className="col-sm-12 col-md-6 col-lg-5 text-center">
                  <div className="rule-box">
                    <h5>MIGHTY MITE</h5>
                    <img className="w-100" src={MIGHTY} alt="MIGHTY" />
                    <Link to={MIGHTY_Pdf} target="_blank" rel="noreferrer">
                      <span className="download-pdf">
                        <FaFileDownload />
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-5 text-center">
                  <div className="rule-box">
                    <h5>PEE WEE</h5>
                    <img className="w-100" src={PEE} alt="PEE" />
                    <Link to={PEE_Pdf} target="_blank" rel="noreferrer">
                      <span className="download-pdf">
                        <FaFileDownload />
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-5 text-center">
                  <div className="rule-box">
                    <h5>JUNIOR</h5>
                    <img className="w-100" src={Junior} alt="Junior" />
                    <Link to={Junior_Pdf} target="_blank" rel="noreferrer">
                      <span className="download-pdf">
                        <FaFileDownload />
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-5 text-center">
                  <div className="rule-box">
                    <h5>J.R. TEEN</h5>
                    <img className="w-100" src={Teen} alt="Teen" />
                    <Link to={Teen_Pdf} target="_blank" rel="noreferrer">
                      <span className="download-pdf">
                        <FaFileDownload />
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-5 text-center">
                  <div className="rule-box">
                    <h5>TEENAGER</h5>
                    <img className="w-100" src={TeanAger} alt="TeanAger" />
                    <Link to={TeanAger_Pdf} target="_blank" rel="noreferrer">
                      <span className="download-pdf">
                        <FaFileDownload />
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-5 text-center">
                  <div className="rule-box">
                    <h5>ADULT & MASTERS</h5>
                    <img className="w-100" src={Adult} alt="Adult" />
                    <Link to={Adult_Pdf} target="_blank" rel="noreferrer">
                      <span className="download-pdf">
                        <FaFileDownload />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Info;
