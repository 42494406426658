import React, { useState } from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import "./header.scss";
import Logo from "../../images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { useAuth } from "../../contexts/AuthContext";

function Header() {
  const [expanded, setExpanded] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate(); // Initialize navigation
  const auth = getAuth();

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.error("Logout error:", error);
      });
  };

  return (
    <>
      <header className="header-sec">
        <div className="top-hdr">
          <Container>
            <div className="row top-header">
              <div className="col-12 text-end">
                {user ? (
                  <>
                    <Link
                      onClick={() => {
                        handleLogout();
                        setExpanded(false);
                      }}
                      to="#"
                    >
                      Logout
                    </Link>
                  </>
                ) : (
                  <>
                    <Link
                      onClick={() => setExpanded(false)}
                      to="/register"
                      className="text-nowrap"
                    >
                      Sign Up
                    </Link>
                    <Link onClick={() => setExpanded(false)} to="/login">
                      LOGIN
                    </Link>
                  </>
                )}
              </div>
            </div>
          </Container>
        </div>
        <Container className="position-relative" style={{ zIndex: "2" }}>
          <div className="row">
            <div className="col-12">
              <Navbar expand="xl" expanded={expanded}>
                <Navbar.Brand
                  onClick={() => setExpanded(false)}
                  as={Link}
                  className="p-0"
                  to="/"
                >
                  <img src={Logo} alt="Logo" />
                </Navbar.Brand>
                <Navbar.Toggle
                  onClick={() => setExpanded(!expanded)}
                  aria-controls="navbarScroll"
                />
                <Navbar.Collapse
                  id="navbarScroll"
                  className="justify-content-end"
                >
                  <Nav>
                    <Nav.Link
                      onClick={() => setExpanded(false)}
                      as={Link}
                      to="/"
                    >
                      Home
                    </Nav.Link>
                    {user && (
                      <Nav.Link
                        onClick={() => setExpanded(false)}
                        as={Link}
                        to="/dashboard"
                      >
                        Dashboard
                      </Nav.Link>
                    )}
                    {user && user.approved && (
                      <Nav.Link
                        onClick={() => setExpanded(false)}
                        as={Link}
                        to="/register-academy"
                        className="text-nowrap"
                      >
                        Register Academy
                      </Nav.Link>
                    )}
                    <Nav.Link
                      onClick={() => setExpanded(false)}
                      as={Link}
                      to="/events"
                    >
                      Events
                    </Nav.Link>
                    <Nav.Link
                      onClick={() => setExpanded(false)}
                      as={Link}
                      to="/black-belts"
                    >
                      Black Belts
                    </Nav.Link>
                    <Nav.Link
                      onClick={() => setExpanded(false)}
                      as={Link}
                      to="/academies"
                    >
                      Academies
                    </Nav.Link>
                    <Nav.Link
                      onClick={() => setExpanded(false)}
                      as={Link}
                      to="/information"
                    >
                      Info
                    </Nav.Link>
                    <Nav.Link
                      onClick={() => setExpanded(false)}
                      as={Link}
                      to="/our-sponsors"
                    >
                      Sponsors
                    </Nav.Link>
                    <Nav.Link
                      onClick={() => setExpanded(false)}
                      as={Link}
                      to="/contact-us"
                    >
                      Contact Us
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </div>
          </div>
        </Container>
      </header>
    </>
  );
}

export default Header;
