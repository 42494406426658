import React from "react";
import { Container } from "react-bootstrap";
import "./events.scss";
import { Link } from "react-router-dom";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
// import qr from "../../images/qr-pfbjj.jpg";
import PacificOpen from "../event/PacificOpen";

function UpcomingEvents() {
  return (
    <div className="section-padding upcoming-events">
      <Container>
        <h2>Upcoming Events</h2>
        {/* <div className="row justify-content-center mb-5">
          <p>
            All events payments can be sent to:{" "}
            <a href="https://venmo.com/u/pfbjj">Venmo @PFBJJ</a>
          </p>
          <img
            src={qr}
            alt="qr code"
            className="w-auto"
            style={{ height: "200px" }}
          />
        </div> */}
        <PacificOpen />
        <div className="row justify-content-center mt-4"></div>
        <div className="view-events">
          <Link to="/events">
            <button>View all events</button>
          </Link>
        </div>
      </Container>
    </div>
  );
}

export default UpcomingEvents;
