import React from "react";
import { Container } from "react-bootstrap";
import "./sponsors.scss";
import SponsorImage from "../../images/sponsors.jpg";
import fuji from "../../images/fujimats.jpg";
import revolusun from "../../images/revolusun.jpg";
import acai from "../../images/acai.png";
import SponsorBox from "./sponsorBox";
import { Helmet } from "react-helmet";

function Sponsors() {
  return (
    <>
      <Helmet>
        <title>Pacific Federation of Brazilian Jiu Jitsu | Our Sponsors</title>
      </Helmet>
      <div className="section-padding our-sponsors">
        <Container>
          <div className="row sponsor-info">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <h3>We Value Our Sponsors & Partners</h3>
              <p>
                At Pacific Federation of Brazilian Jiu Jitsu, we are grateful
                for the generous support of our sponsors. Their commitment and
                contributions play an important role in helping us achieve our
                mission and bring our vision to life.
              </p>
              <p>
                We invite you to become a part of our sponsorship family and
                join us in making a meaningful impact. Your support enables us
                to continue our work, and together we can shape a better future.
              </p>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <img src={SponsorImage} alt="sponsors" className="sponsor" />
            </div>
          </div>

          <div className="row sponsor-list">
            <div className="col-12">
              <h2>Our Sponsors</h2>
              <div className="row justify-content-center">
                <SponsorBox
                  href="https://www.revolusun.com/"
                  img={revolusun}
                  text="Our employees are positive and energetic, and committed to a future
            of renewable energy. We work hard and enjoy life. We collaborate and
            take time to laugh. We’re good at what we do, and most of all — we
            truly care about each other and what we’re doing."
                />
                <SponsorBox
                  href="https://fujimats.com/"
                  img={fuji}
                  text="Founded by passionate and dedicated martial arts
                        professionals, Jimmy Pedro and Lia Hatashita joined
                        forces with two of the industry’s leading facility
                        outfitting specialists – Tom Tschida and Patrick
                        McCarty. Our passion and combined 50+ years of
                        experience brings our clients the highest quality
                        Martial Arts and Fitness Facility systems available. We
                        are excited to help make your dream become a reality by
                        designing and outfitting your facility with our top of
                        the line FUJI products and our expertise!"
                />
                <SponsorBox
                  href="https://www.acairepublic.com/"
                  img={acai}
                  text="Inspired by the small cafes found in Brazil, Açaí
                        Republic offers delicious Smoothies, Açaí Bowls and
                        Salgados (Brazilian pastries). We follow our commitment
                        to inspire you to be happy and energetic through our
                        products, and use only the best ingredients like our
                        organic açaí, harvested and brought directly from the
                        Amazon to freshly mix in our blends so you can enjoy the
                        flavor and the amazing health benefits açaí has to
                        offer."
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Sponsors;
