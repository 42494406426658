import React, { useEffect, useState, useCallback } from "react";
import {
  getUserProfile,
  updateUserProfile,
} from "../firebase/profileFunctions";
import { Container, Button, Form } from "react-bootstrap";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext"; // Import the useAuth hook
import { Helmet } from "react-helmet";

const Dashboard = () => {
  const { user } = useAuth(); // Get the user from AuthContext
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({
    Name: "",
    Belt: "",
    Academy: "",
  });
  const [academies, setAcademies] = useState([]); // State to store academy names
  const [userProfiles, setUserProfiles] = useState([]); // State to store all user profiles

  const fetchUserProfiles = useCallback(async () => {
    const db = getFirestore();
    try {
      const querySnapshot = await getDocs(collection(db, "profiles"));
      const profilesList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUserProfiles(profilesList);
    } catch (error) {
      console.error("Error fetching user profiles:", error);
    }
  }, []);

  useEffect(() => {
    if (!user) return;
    setLoading(true);
    const fetchProfile = async () => {
      try {
        const userProfile = await getUserProfile(user.uid);
        setProfile(userProfile);
        setFormData({
          Name: userProfile.Name,
          Belt: userProfile.Belt,
          Academy: userProfile.Academy,
        });
        if (userProfile.admin) {
          fetchUserProfiles();
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [user, fetchUserProfiles]);

  useEffect(() => {
    const fetchAcademies = async () => {
      const db = getFirestore();
      try {
        const querySnapshot = await getDocs(collection(db, "academies"));
        const academyList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().Academy,
        }));
        setAcademies(academyList);
      } catch (error) {
        console.error("Error fetching academies:", error);
      }
    };

    fetchAcademies();
  }, []);

  const handleEditToggle = () => {
    setEditMode(!editMode);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await updateUserProfile(user.uid, formData);
      setProfile((prevProfile) => ({ ...prevProfile, ...formData }));
      setEditMode(false);
      // Re-fetch user profiles after saving
      if (profile?.admin) {
        await fetchUserProfiles();
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="container my-5">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-8">Loading...</div>
        </div>
      </div>
    );
  }

  if (!profile) {
    return (
      <div className="container my-5">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-8">Not logged in.</div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Pacific Federation of Brazilian Jiu Jitsu | Dashboard</title>
      </Helmet>
      <div className="section-padding signup-form">
        <Container>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6">
              <h2>User Profile</h2>
              {editMode ? (
                <Form onSubmit={handleSave}>
                  <Form.Group>
                    <Form.Label className="mb-1">Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="Name"
                      value={formData.Name}
                      onChange={handleChange}
                      className="mb-3"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="mb-1">Belt</Form.Label>
                    <Form.Control
                      as="select"
                      name="Belt"
                      value={formData.Belt}
                      onChange={handleChange}
                      className="mb-3"
                    >
                      <option value="" disabled>
                        Adults
                      </option>
                      <option value="White">White</option>
                      <option value="Blue">Blue</option>
                      <option value="Purple">Purple</option>
                      <option value="Brown">Brown</option>
                      <option value="Black">Black</option>
                      <option value="" disabled>
                        Kids
                      </option>
                      <option value="White">White</option>
                      <option value="Grey">Grey</option>
                      <option value="Yellow">Yellow</option>
                      <option value="Orange">Orange</option>
                      <option value="Green">Green</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="mb-1">Academy</Form.Label>
                    <Form.Control
                      as="select"
                      name="Academy"
                      value={formData.Academy}
                      onChange={handleChange}
                      className="mb-3"
                    >
                      {academies.map((academy) => (
                        <option key={academy.id} value={academy.name}>
                          {academy.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Button className="me-2" variant="primary" type="submit">
                    Save
                  </Button>
                  <Button variant="secondary" onClick={handleEditToggle}>
                    Cancel
                  </Button>
                </Form>
              ) : (
                <>
                  <p>Name: {profile.Name}</p>
                  <p>Email: {profile.Email}</p>
                  <p>Belt: {profile.Belt}</p>
                  <p>Academy: {profile.Academy}</p>
                  <Button onClick={handleEditToggle}>Edit</Button>
                </>
              )}
            </div>
          </div>
          {profile && profile.admin && (
            <div className="row justify-content-center mt-5">
              <div className="col-12 col-lg-6">
                <h2>All Athletes</h2>
                <ul style={{ listStyle: "none", paddingLeft: 0 }}>
                  {userProfiles.map((user) => (
                    <li key={user.id} style={{ marginBottom: "1rem" }}>
                      {user.Name}
                      <span
                        style={{
                          color: "darkorange",
                          fontWeight: "600",
                          marginLeft: "10px",
                        }}
                      >
                        {user.Academy}
                      </span>
                      <br />
                      {user.Belt} Belt
                      <hr />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </Container>
      </div>
    </>
  );
};

export default Dashboard;
