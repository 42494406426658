import "./firebase/firebaseConfig"; // Import first
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./components/extra-css/signup.scss";
import "./components/extra-css/register.scss";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthProvider } from "./contexts/AuthContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
);
