import React, { useState } from "react";
import { login, resetPassword } from "../firebase/firebaseFunctions.js";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

const Authentication = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorLogin, setErrorLogin] = useState("");

  const navigate = useNavigate(); // Initialize navigation

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const user = await login(email, password);
      if (user) {
        navigate("/dashboard");
      } else {
        // If login function returns null or undefined without throwing an error
        setErrorLogin("Email or Password incorrect.");
      }
    } catch (error) {
      // Catch any errors thrown during the login attempt
      setErrorLogin("Email or Password incorrect.");
    }
  };

  const handleResetPassword = (event) => {
    event.preventDefault();
    if (!email) {
      setErrorLogin("Enter email address and click Forgot Password.");
    } else {
      setErrorLogin("If user email exists, you will receive an email.");
      resetPassword(email);
    }
  };

  return (
    <>
      <Helmet>
        <title>Pacific Federation of Brazilian Jiu Jitsu | Login</title>
      </Helmet>
      <div className="section-padding signup-form">
        <Container>
          <h2>Login</h2>
          <form onSubmit={handleSubmit} className="signup">
            <div className="form-group">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <input
                    type="text"
                    placeholder="Email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              {errorLogin && (
                <p className="text-center" style={{ color: "red" }}>
                  {errorLogin}
                </p>
              )}
              <button
                type="submit"
                className="submit-btn button-auto-width"
                style={{ marginRight: "20px" }}
              >
                Log In
              </button>
              <button
                onClick={handleResetPassword}
                className="submit-btn button-auto-width"
              >
                Forgot Password
              </button>
            </div>
          </form>
        </Container>
      </div>
    </>
  );
};

export default Authentication;
