export default function sponsorBox({ href, img, text }) {
  return (
    <div className="col-sm-12 col-md-4 col-lg-4">
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className="text-decoration-none text-black"
      >
        <div className="sponsorbox">
          <img src={img} alt="sponsors" className="sponsor" />
          <p className="mt-4 mb-0">{text}</p>
        </div>
      </a>
    </div>
  );
}
