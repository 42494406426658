import React from 'react';
import ContactForm from '../../components/contact-form/ContactForm.jsx';

function ContactUs() {
 return (
  <div>
   <ContactForm />
  </div>
 )
}

export default ContactUs;