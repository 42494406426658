import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "./contactform.scss";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

function ContactForm() {
  const form = useRef();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errorName, setErrorName] = useState("formInput");
  const [errorEmail, setErrorEmail] = useState("formInput");
  const [errorPhone, setErrorPhone] = useState("formInput");
  const [errorSubject, setErrorSubject] = useState("formInput");
  const [errorMessage, setErrorMessage] = useState("formInput");
  const [waitMessage, setWaitMessage] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    if (!name) {
      setErrorName("errorFormInput");
    } else setErrorName("formInput");
    if (!email) {
      setErrorEmail("errorFormInput");
    } else setErrorEmail("formInput");
    if (!phone) {
      setErrorPhone("errorFormInput");
    } else setErrorPhone("formInput");
    if (!subject) {
      setErrorSubject("errorFormInput");
    } else setErrorSubject("formInput");
    if (!message) {
      setErrorMessage("errorFormInput");
    } else setErrorMessage("formInput");

    if (name && email && phone && subject && message) {
      setWaitMessage("Please wait...");

      emailjs
        .sendForm(
          "service_nd73kve",
          "template_mpmpuar",
          form.current,
          "lIvuQcS29W-1LzLJc"
        )
        .then(
          function (response) {
            setName("");
            setEmail("");
            setPhone("");
            setSubject("");
            setMessage("");
            setWaitMessage("Your message has been sent.");
          },
          function (err) {
            setWaitMessage(
              "There was an error. Please reload the page and try again"
            );
          }
        );
    } else {
      setWaitMessage("");
    }
  };

  return (
    <>
      <Helmet>
        <title>Pacific Federation of Brazilian Jiu Jitsu | Contact Us</title>
      </Helmet>
      <div className="section-padding contact-form">
        <Container>
          <h2>Contact Us</h2>
          <div className="contactus">
            <form ref={form} id="jobsForm" onSubmit={onSubmit}>
              <div className="form-group">
                <input
                  className={`${errorName} form-control`}
                  value={name}
                  name="name"
                  type="text"
                  placeholder="Full Name"
                  onChange={(e) => {
                    setName(e.target.value);
                    setErrorName("formInput");
                  }}
                />
              </div>
              <div className="form-group">
                <input
                  className={`${errorEmail} form-control`}
                  value={email}
                  name="email"
                  type="email"
                  placeholder="Email Address"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setErrorEmail("formInput");
                  }}
                />
              </div>
              <div className="form-group">
                <input
                  className={`${errorPhone} form-control`}
                  value={phone}
                  name="phone"
                  type="tel"
                  placeholder="Phone Number"
                  onChange={(e) => {
                    setPhone(e.target.value);
                    setErrorPhone("formInput");
                  }}
                />
              </div>
              <div className="form-group">
                <input
                  className={`${errorSubject} form-control`}
                  value={subject}
                  name="subject"
                  type="text"
                  placeholder="Subject"
                  onChange={(e) => {
                    setSubject(e.target.value);
                    setErrorSubject("formInput");
                  }}
                />
              </div>
              <div className="form-group">
                <textarea
                  rows="4"
                  className={`${errorMessage} form-control`}
                  value={message}
                  name="message"
                  placeholder="Your Message"
                  onChange={(e) => {
                    setMessage(e.target.value);
                    setErrorMessage("formInput");
                  }}
                />
              </div>
              {waitMessage && (
                <p className="text-center waitMessage">
                  <em>{waitMessage}</em>
                </p>
              )}
              <div className="row justify-content-center">
                <button type="submit" className="submit-btn button-auto-width">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Container>
      </div>
    </>
  );
}

export default ContactForm;
