import {
  doc,
  setDoc,
  getDoc,
  getDocs,
  collection,
  addDoc,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import { db } from "./firebaseConfig";

// Function to create or update a user's profile
export const createUserProfile = async (userId, profileData) => {
  try {
    await setDoc(doc(db, "profiles", userId), profileData);
  } catch (error) {
    console.error("Error creating or updating user profile:", error.message);
  }
};

// Function to create an academy
export const createAcademy = async (academyData) => {
  try {
    const standardizedName = academyData.Academy.toUpperCase();

    // Create a query to check for existing academies with the same name
    const q = query(
      collection(db, "academies"),
      where("Academy", "==", standardizedName)
    );
    const existingDocs = await getDocs(q);

    if (!existingDocs.empty) {
      throw new Error("An academy with this name already exists."); // Explicitly throw an error
    }

    // No existing academy found, proceed to add new document
    await addDoc(collection(db, "academies"), academyData);
    return true; // Indicate success
  } catch (error) {
    console.error("Error adding academy:", error.message);
    throw error;
  }
};

// Function to get a user's profile
export const getUserProfile = async (userId) => {
  try {
    const docRef = doc(db, "profiles", userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.error("No profile found for this user.");
      return null;
    }
  } catch (error) {
    console.error("Error retrieving user profile:", error.message);
    return null;
  }
};

// Function to update user profile in Firestore
export const updateUserProfile = async (userId, profileData) => {
  try {
    const userDoc = doc(db, "profiles", userId);
    await updateDoc(userDoc, profileData);
    console.log("Profile updated successfully");
  } catch (error) {
    console.error("Error updating user profile:", error);
    throw error; // Rethrow the error to handle it in the calling component
  }
};
