import React from 'react';
import Info from '../../components/info/Info';

function Information() {
 return (
  <>
   <Info />
  </>
 )
}

export default Information;