import { Container } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { db } from "../firebase/firebaseConfig";
import { collection, query, where, getDocs } from "firebase/firestore";
import { Helmet } from "react-helmet";

const Blackbelts = () => {
  const [blackBelts, setBlackBelts] = useState([]);

  useEffect(() => {
    const fetchBlackBelts = async () => {
      const q = query(
        collection(db, "profiles"),
        where("Belt", "==", "Black"),
        where("approved", "==", true)
      );
      const querySnapshot = await getDocs(q);
      const allBlackBelts = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBlackBelts(allBlackBelts);
    };

    fetchBlackBelts();
  }, []);

  return (
    <>
      <Helmet>
        <title>Pacific Federation of Brazilian Jiu Jitsu | Black Belts</title>
      </Helmet>
      <div className="section-padding signup-form">
        <Container>
          <h2>Black Belts</h2>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6">
              {blackBelts.map((name) => (
                <p className="mb-2" style={{ fontSize: "18px" }} key={name.id}>
                  {name.Name}
                  <span
                    style={{
                      color: "darkorange",
                      fontWeight: "600",
                      marginLeft: "12px",
                    }}
                  >
                    {name.Academy}
                  </span>
                </p>
              ))}
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Blackbelts;
