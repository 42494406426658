import React from "react";
import wsd1 from "../../images/events/womens/womensevent (1).jpg";
import wsd2 from "../../images/events/womens/womensevent (2).jpg";
import wsd3 from "../../images/events/womens/womensevent (3).jpg";
import wsd4 from "../../images/events/womens/womensevent (4).jpg";
import wsd5 from "../../images/events/womens/womensevent (5).jpg";
import wsd6 from "../../images/events/womens/womensevent (6).jpg";
import wsd7 from "../../images/events/womens/womensevent (7).jpg";
import wsd8 from "../../images/events/womens/womensevent (8).jpg";
import wsd9 from "../../images/events/womens/womensevent (9).jpg";
import wsd10 from "../../images/events/womens/womensevent (10).jpg";
import wsd11 from "../../images/events/womens/womensevent (11).jpg";
import wsd12 from "../../images/events/womens/womensevent (12).jpg";
import wsd13 from "../../images/events/womens/womensevent (13).jpg";
import wsd14 from "../../images/events/womens/womensevent (14).jpg";
import wsd15 from "../../images/events/womens/womensevent (15).jpg";
import womensdefense from "../../images/womensselfdefense.jpg";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";

function WomensDefense() {
  const [open2, setOpen2] = React.useState(false);

  const womensSDImages = [
    wsd1,
    wsd2,
    wsd3,
    wsd4,
    wsd5,
    wsd6,
    wsd7,
    wsd8,
    wsd9,
    wsd10,
    wsd11,
    wsd12,
    wsd13,
    wsd14,
    wsd15,
  ];

  return (
    <div className="row justify-content-center text-center mt-4">
      <div className="view-events justify-content-center">
        <img
          src={womensdefense}
          alt="womens self defense"
          className="eventImg"
        />
        <button
          className="view-events d-block m-auto mt-4"
          onClick={() => setOpen2(true)}
        >
          Women's Self Defense Gallery
        </button>
      </div>

      <Lightbox
        open={open2}
        close={() => setOpen2(false)}
        slides={womensSDImages.map((image, index) => ({
          src: image,
          alt: `Image ${index + 1}`,
        }))}
        plugins={[Thumbnails, Fullscreen, Zoom]}
        carousel={{ preload: 6 }}
      />
    </div>
  );
}

export default WomensDefense;
