import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { db } from "../firebase/firebaseConfig";
import { collection, query, getDocs } from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";
import { Helmet } from "react-helmet";

const Academies = () => {
  const [academies, setAcademies] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    const fetchAcademies = async () => {
      try {
        const q = query(collection(db, "academies"));
        const querySnapshot = await getDocs(q);
        const names = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAcademies(names);
      } catch (error) {
        console.error("Error fetching academies:", error);
      }
    };

    fetchAcademies();
  }, []);

  return (
    <>
      <Helmet>
        <title>Pacific Federation of Brazilian Jiu Jitsu | Academies</title>
      </Helmet>
      <div className="section-padding signup-form">
        <Container>
          <h2>Academies</h2>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-7">
              <ul style={{ listStyle: "none", paddingLeft: "0" }}>
                {academies.map((academy) => (
                  <li style={{ fontSize: "18px" }} key={academy.id}>
                    {academy.Academy}
                    <span
                      style={{
                        color: "darkorange",
                        fontWeight: "600",
                        marginLeft: "12px",
                      }}
                    >
                      {user && user.admin && <span>{academy.Address}, </span>}
                      {academy.City}, {academy.State} {academy.Country}
                    </span>
                    {user && user.admin && (
                      <>
                        <p className="mb-0">
                          <span className="fs-6">Phone:</span> {academy.Phone}
                        </p>
                        <p className="mb-0">
                          <span className="fs-6">Head Instructor:</span>{" "}
                          {academy.Professor}
                        </p>
                      </>
                    )}
                    <hr />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Academies;
