import React from "react";
import whatsappIcon from "../images/whatsapp.png";

const WhatsAppChat = () => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const whatsappLink = isMobile
    ? "whatsapp://send?phone=+18083066306&text=Hello"
    : "https://web.whatsapp.com/send?phone=+18083066306&text=Hello";

  return (
    <a
      href={whatsappLink}
      target="_blank"
      rel="noopener noreferrer"
      style={styles.link}
    >
      <img src={whatsappIcon} alt="Chat on WhatsApp" style={styles.icon} />
    </a>
  );
};

const styles = {
  link: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
    zIndex: 1000,
  },
  icon: {
    height: "70px",
    width: "70px",
  },
};

export default WhatsAppChat;
