import React from "react";
import { Container } from "react-bootstrap";
import "./event.scss";
// import qr from "../../images/qr-pfbjj.jpg";
import PacificOpen from "./PacificOpen";
import WomensDefense from "./WomensDefense";
import { Helmet } from "react-helmet";

function Event() {
  return (
    <>
      <Helmet>
        <title>Pacific Federation of Brazilian Jiu Jitsu | Events</title>
      </Helmet>
      <div className="section-padding event-sec">
        <Container>
          <h2 className="text-center">Upcoming Events</h2>
          {/* <div className="row justify-content-center text-center mb-5">
            <p>
              All events payments can be sent to:{" "}
              <a href="https://venmo.com/u/pfbjj">Venmo @PFBJJ</a>
            </p>
            <img
              src={qr}
              alt="qr code"
              className="w-auto"
              style={{ height: "200px" }}
            />
          </div> */}
          <PacificOpen />
          <h2 className="text-center mt-5">Previous Events</h2>
          <WomensDefense />
        </Container>
      </div>
    </>
  );
}

export default Event;
