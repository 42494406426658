import React from 'react';
import Sponsors from '../../components/sponsors/Sponsors.jsx';


function OurSponsors() {
 return (
  <>
   <Sponsors />
  </>
 )
}

export default OurSponsors;