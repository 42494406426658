import React from "react";
import HomeBanner from "../../components/home-hero-banner/Home-Banner";
import UpcomingEvents from "../../components/upcomin-event/UpcomingEvents";
// import Ranking from "../../components/player-ranking/Ranking";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <>
      <Helmet>
        <title>Pacific Federation of Brazilian Jiu Jitsu | Home</title>
      </Helmet>
      <HomeBanner />
      <UpcomingEvents />
      {/* <Ranking /> */}
    </>
  );
}

export default Home;
