import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "./firebaseConfig";
import { createUserProfile } from "./profileFunctions";

// Function to sign up a new user
export const signUp = async (email, password, profileData) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const userId = userCredential.user.uid;

    const completeProfileData = {
      ...profileData,
      Email: email,
    };

    // Create user's profile in Firestore
    await createUserProfile(userId, completeProfileData);

    return userCredential;
  } catch (error) {
    console.error("Error signing up:", error.message);
    throw error;
  }
};

// Function to log in a user
export const login = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    return userCredential.user; // Return the logged-in user upon success
  } catch (error) {
    console.error("Error logging in:", error.message);
    throw error;
  }
};

// Function to send a password reset email
export const resetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error) {
    console.error("Error sending password reset email:", error.message);
  }
};
