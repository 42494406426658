import React from "react";
import "./hero-banner.scss";
import video from "../../images/pfbjj.mp4";

function HomeBanner() {
  return (
    <div className="hero-sec">
      <video className="video-background" autoPlay loop muted>
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default HomeBanner;
