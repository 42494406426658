import React from 'react';
import Event from '../../components/event/Event';

function Events() {
 return (
  <>
   <Event />
  </>
 )
}

export default Events;