import React, { useState, useEffect } from "react";
import { signUp } from "../firebase/firebaseFunctions";
import { Container } from "react-bootstrap";
import {
  doc,
  setDoc,
  getFirestore,
  collection,
  getDocs,
} from "firebase/firestore";
import { Link } from "react-router-dom";
import { db } from "../firebase/firebaseConfig";
import { Helmet } from "react-helmet";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [profileData, setProfileData] = useState({
    Name: "",
    Belt: "",
    Academy: "",
    YearReceived: "",
    BlackBeltGiver: "",
  });

  const [blackBeltMessage, setBlackBeltMessage] = useState("");

  const [academies, setAcademies] = useState([]); // State to store academy names
  const [errorData, setErrorData] = useState({
    errorName: "",
    errorEmail: "",
    errorBelt: "",
    errorAcademy: "",
    errorPassword: "",
    errorYearReceived: "",
    errorBlackBeltGiver: "",
  });

  const handleBeltChange = (event) => {
    const selectedBelt = event.target.value;
    setProfileData((prevData) => ({
      ...prevData,
      Belt: selectedBelt,
      YearReceived: selectedBelt === "Black" ? prevData.YearReceived : "",
      BlackBeltGiver: selectedBelt === "Black" ? prevData.BlackBeltGiver : "",
    }));
  };

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const db = getFirestore();
    const fetchAcademies = async () => {
      const querySnapshot = await getDocs(collection(db, "academies"));
      const academyList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().Academy,
      }));
      setAcademies(academyList);
    };

    fetchAcademies();
  }, []);

  const handleSignUp = async () => {
    let valid = true;

    if (!email) {
      setErrorData((prev) => ({ ...prev, errorEmail: "errorFormInput" }));
      valid = false;
    } else {
      setErrorData((prev) => ({ ...prev, errorEmail: "" }));
    }

    if (!password) {
      setErrorData((prev) => ({ ...prev, errorPassword: "errorFormInput" }));
      valid = false;
    } else {
      setErrorData((prev) => ({ ...prev, errorPassword: "" }));
    }

    if (!profileData.Name) {
      setErrorData((prev) => ({ ...prev, errorName: "errorFormInput" }));
      valid = false;
    } else {
      setErrorData((prev) => ({ ...prev, errorName: "" }));
    }

    if (!profileData.Belt) {
      setErrorData((prev) => ({ ...prev, errorBelt: "errorFormInput" }));
      valid = false;
    } else {
      setErrorData((prev) => ({ ...prev, errorBelt: "" }));
    }

    if (!profileData.Academy) {
      setErrorData((prev) => ({ ...prev, errorAcademy: "errorFormInput" }));
      valid = false;
    } else {
      setErrorData((prev) => ({ ...prev, errorAcademy: "" }));
    }

    if (profileData.Belt === "Black" && !profileData.YearReceived) {
      setErrorData((prev) => ({
        ...prev,
        errorYearReceived: "errorFormInput",
      }));
      valid = false;
    } else {
      setErrorData((prev) => ({ ...prev, errorYearReceived: "" }));
    }

    if (profileData.Belt === "Black" && !profileData.BlackBeltGiver) {
      setErrorData((prev) => ({
        ...prev,
        errorBlackBeltGiver: "errorFormInput",
      }));
      valid = false;
    } else {
      setErrorData((prev) => ({ ...prev, errorBlackBeltGiver: "" }));
    }

    if (valid) {
      const completeProfileData = { ...profileData, Email: email };

      try {
        const user = await signUp(email, password, completeProfileData);
        if (user) {
          const userId = user.user.uid;
          setErrorMessage("");
          setSuccessMessage("Registration Successful.");
          setBlackBeltMessage("");

          const confirmationEmailData = {
            to: [email],
            message: {
              subject: "Pacific Federation of BJJ Confirmation",
              html: 'Thank you for registering to Pacific Federation of Brazilian Jiu Jitsu.<br /><br />Please download your athlete waiver here: <a href="https://www.pacificfederationbjj.com/pfbjj-tournament-waiver.pdf">Waiver</a>',
            },
          };
          try {
            // Create a document in the Firestore collection monitored by the Trigger Email extension
            await setDoc(doc(collection(db, "emails")), confirmationEmailData);
          } catch (error) {
            console.error(
              "Failed to create email trigger confirmation document:",
              error
            );
            return; // Exit if unable to create the trigger document
          }

          if (profileData.Belt === "Black") {
            // Data structure for Trigger Email
            setBlackBeltMessage("Black Belt under review.");
            const emailTriggerData = {
              to: ["pacificfederationbjj@gmail.com"],
              message: {
                subject: "Black Belt Registration",
                html: `A new Black Belt has been submitted.<br/>
                        Name: ${profileData.Name}<br/>
                        Academy: ${profileData.Academy}<br />
                        Given by: ${profileData.BlackBeltGiver}<br />
                        Year received: ${profileData.YearReceived}
                        <br /><br />
                        <a href="https://us-central1-pfbjj-42aba.cloudfunctions.net/userActions/approve?userId=${userId}"><button style="
                        color: #ffffff;
                        background-color: #008000;
                        font-size: 18px;
                        border: 1px solid #2d63c8;
                        padding: 15px;
                        margin-bottom:20px;
                        margin-right:10px;
                        border-radius:5px;
                        width:150px;">Approve</button></a> <a href="https://us-central1-pfbjj-42aba.cloudfunctions.net/userActions/reject?userId=${userId}"><button style="
                        color: #ffffff;
                        background-color: #ce0000;
                        font-size: 18px;
                        border: 1px solid #2d63c8;
                        padding: 15px;
                        margin-bottom:20px;
                        border-radius:5px;
                        width:150px;">Reject</button></a>`,
              },
            };
            try {
              // Create a document in the Firestore collection monitored by the Trigger Email extension
              await setDoc(doc(collection(db, "emails")), emailTriggerData);
            } catch (error) {
              console.error(
                "Failed to create black belt email trigger document:",
                error
              );
              return; // Exit if unable to create the trigger document
            }
          }
        }
      } catch (e) {
        // Check for specific error codes and set appropriate messages
        if (e.message.includes("auth/email-already-in-use")) {
          setErrorMessage("Email address is already registered.");
          setSuccessMessage("");
          setBlackBeltMessage("");
        } else if (e.message.includes("auth/invalid-email")) {
          setErrorMessage("Please enter a valid email address.");
          setSuccessMessage("");
          setBlackBeltMessage("");
        } else if (e.message.includes("Password should be")) {
          setErrorMessage("Password must be 6 characters minimum.");
          setSuccessMessage("");
          setBlackBeltMessage("");
        } else {
          setErrorMessage("Registration failed. Please try again.");
          setSuccessMessage("");
          setBlackBeltMessage("");
        }
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Pacific Federation of Brazilian Jiu Jitsu | Register</title>
      </Helmet>
      <div className="section-padding signup-form">
        <Container>
          <h2>Sign Up</h2>
          <div className="signup">
            <div className="form-group">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <input
                    className={errorData.errorEmail}
                    type="text"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <input
                    className={errorData.errorPassword}
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
            </div>
            {/* Fields for the profile data */}
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <input
                  className={errorData.errorName}
                  type="text"
                  placeholder="Name"
                  value={profileData.Name}
                  onChange={(e) =>
                    setProfileData({ ...profileData, Name: e.target.value })
                  }
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <select
                  className={errorData.errorBelt}
                  value={profileData.Belt}
                  onChange={handleBeltChange}
                >
                  <option value="" disabled>
                    Select Belt
                  </option>
                  <option value="" disabled>
                    Adults
                  </option>
                  <option value="White">White</option>
                  <option value="Blue">Blue</option>
                  <option value="Purple">Purple</option>
                  <option value="Brown">Brown</option>
                  <option value="Black">Black</option>
                  <option value="" disabled>
                    Kids
                  </option>
                  <option value="White">White</option>
                  <option value="Grey">Grey</option>
                  <option value="Yellow">Yellow</option>
                  <option value="Orange">Orange</option>
                  <option value="Green">Green</option>
                </select>
              </div>
            </div>
            {profileData.Belt === "Black" && (
              <div className="row justify-content-center">
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <input
                    className={errorData.errorYearReceived}
                    type="text"
                    placeholder="Year you received your black belt"
                    value={profileData.YearReceived}
                    onChange={(e) =>
                      setProfileData({
                        ...profileData,
                        YearReceived: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <input
                    className={errorData.errorBlackBeltGiver}
                    type="text"
                    placeholder="Who gave you your black belt"
                    value={profileData.BlackBeltGiver}
                    onChange={(e) =>
                      setProfileData({
                        ...profileData,
                        BlackBeltGiver: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            )}
            <div className="row justify-content-center">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <select
                  className={errorData.errorAcademy}
                  value={profileData.Academy}
                  onChange={(e) =>
                    setProfileData({ ...profileData, Academy: e.target.value })
                  }
                >
                  <option value="" disabled>
                    Select Academy
                  </option>
                  {academies.map((academy) => (
                    <option key={academy.id} value={academy.name}>
                      {academy.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row justify-content-center">
              {errorMessage && (
                <p className="text-center" style={{ color: "red" }}>
                  {errorMessage}
                </p>
              )}
              {successMessage && (
                <p className="text-center" style={{ color: "red" }}>
                  {successMessage}{" "}
                  <Link to="/dashboard">Go to your dashboard</Link>.
                </p>
              )}
              {blackBeltMessage && (
                <p className="text-center" style={{ color: "red" }}>
                  {blackBeltMessage}
                </p>
              )}
              <button
                className="submit-btn button-auto-width"
                onClick={handleSignUp}
              >
                Sign Up
              </button>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Register;
